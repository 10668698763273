import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AgGridEvent, ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment';

import { AmendType } from '@pa/references/idf';

import { numberWithCommas } from '../../../core/utils/number';
import { interestedPartyTypeFormatter } from '../../../../../src/app/core/utils/value.formatters';
import { AssetModel } from '../types';
import { Pilot } from '@pa/sdk/rmf';
import { PilotProposal } from '@pa/sdk/idf';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'aircraft-info',
    templateUrl: './aircraft-info.component.html',
    styleUrls: ['./aircraft-info.component.scss'],
})
export class AircraftInfoComponent implements OnInit {
    @Input() asset: AssetModel;
    @Output() validated = new EventEmitter<boolean>();

    public endorsementsValid: boolean = false;
    public existingPilots: Pilot[];
    public existingPilotsGridOptions: GridOptions;
    public interestedParties: any[] = [];
    public interestedPartiesGridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
        },
        columnDefs: [
            {
                headerName: 'Name',
                field: 'name',
            },
            {
                headerName: 'Interest',
                field: 'type',
                valueFormatter: interestedPartyTypeFormatter,
            },
        ],
    };
    public pilotsGridOptions: GridOptions;
    public premiumsValid: boolean = false;
    public showExistingPolicy = false;

    ngOnInit() {
        this.existingPilots = this.asset.existingPilots ?? [];
        this.showExistingPolicy = !!this.asset.assetPolicy;

        const hasSingleFlightTimeFeature = () => {
            return (
                this.asset.pilots.every((p) => (p.flightTimesByFeature ?? []).length <= 1) &&
                new Set(this.asset.pilots.flatMap((p) => (p.flightTimesByFeature ?? []).map((f) => f.type))).size === 1
            );
        };

        const columnDefs: ColDef[] = [
            {
                headerName: 'First Name',
                field: 'givenName',
            },
            {
                headerName: 'Last Name',
                field: 'familyName',
            },
            {
                headerName: 'DOB',
                field: 'dob',
                hide: (this.asset.pilots?.find((d) => d?.dob) ?? undefined) === undefined,
                valueFormatter: (params) => (params.value ? moment(params.value).format('DD-MMM-YYYY') : undefined),
            },
            {
                headerName: 'Total Flight Time',
                field: 'totalFlightTime',
                valueFormatter: (params) => numberWithCommas(params.value),
                hide: hasSingleFlightTimeFeature(),
            },
            ...getPilotFeatureTimeFields(this.asset.pilots),
            {
                headerName: 'Total Type',
                field: 'totalAircraftFlightTime',
                valueFormatter: (params) => numberWithCommas(params.value),
            },
            {
                headerName: 'Total Type (Past 90 days)',
                field: 'totalAircraftFlightTimeLast90Days',
                hide: (this.asset.pilots?.[0]?.totalAircraftFlightTimeLast90Days ?? undefined) === undefined,
                valueFormatter: (params) => numberWithCommas(params.value),
            },
            {
                headerName: 'Total Type (Past 12 months)',
                field: 'totalAircraftFlightTimeLast12Months',
                hide: (this.asset.pilots?.[0]?.totalAircraftFlightTimeLast12Months ?? undefined) === undefined,
                valueFormatter: (params: ValueFormatterParams) => numberWithCommas(params.value),
            },
        ];

        if (this.showExistingPolicy) {
            this.existingPilotsGridOptions = {
                defaultColDef: {
                    resizable: true,
                },
                columnDefs,
                overlayNoRowsTemplate: '<span>No existing pilots.</span>',
            };
        }

        this.pilotsGridOptions = {
            defaultColDef: {
                resizable: true,
            },
            columnDefs,
            overlayNoRowsTemplate: '<span>No proposed pilots.</span>',
        };
    }

    rowDataChanged(params: AgGridEvent, gridOptions: GridOptions, triggerValidation = false) {
        // if (this.to.autoWidth === 'content') {
        //     // Fit content width
        //     const columnApi = params.columnApi;
        //     const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
        //     columnApi.autoSizeColumns(allColumnIds);
        // } else {
        //     // Fit window width
        //     params.api.sizeColumnsToFit();
        // }
        this.resize(params, gridOptions);
    }

    resize(params: AgGridEvent, gridOptions: GridOptions) {
        if (params?.columnApi.getColumns()) {
            const columnApi = params.columnApi;
            const allColumnIds = columnApi.getColumns().map((c: any) => c.getColId());
            columnApi.autoSizeColumns(allColumnIds);
        }
        gridOptions?.api?.sizeColumnsToFit();
    }

    onPremiumsValidated(isValid: boolean) {
        this.premiumsValid = isValid;
        this.validate();
    }

    onEndorsementsValidated(isValid: boolean) {
        this.endorsementsValid = isValid;
        this.validate();
    }

    validate(): void {
        this.validated.emit(
            this.premiumsValid && (this.asset.amendType === AmendType.removed || this.endorsementsValid)
        );
    }
}

function getPilotFeatureTimeFields(pilots: PilotProposal[]) {
    const allFeaturesTypes = pilots
        .flatMap((p) => p.flightTimesByFeature)
        .flatMap((f) => f.type)
        .filter((value, index, self) => self.indexOf(value) === index);

    return allFeaturesTypes.map((type) => {
        const typeDisplay = type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, ' $1');

        const colDef: ColDef = {
            headerName: `Total ${typeDisplay} Time`,
            field: `flightTimesByFeature_${type}`,
            valueGetter: (params: ValueGetterParams<PilotProposal>) =>
                params.data.flightTimesByFeature.find((f) => f.type === type)?.flightTime,
            valueFormatter: ({ value }) => numberWithCommas(value),
            cellClass: `data-testid-flightTimesByFeature_${type}`,
        };

        return colDef;
    });
}
