import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IDF_ADDRESS } from '@pa/lib-spa';
import { IdfResponse, Organisation, Originator } from '@pa/sdk/idf';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IdfService {
    constructor(private http: HttpClient, @Inject(IDF_ADDRESS) private idfAddress: string) {}

    saveOriginator(originator) {
        const uriString = `${this.idfAddress}/v1/originators`;
        return this.http.post<IdfResponse<Originator>>(uriString, originator).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    saveOrganisation(organisation) {
        const uriString = `${this.idfAddress}/v1/organisations?url`;
        return this.http.post<IdfResponse<Organisation>>(uriString, organisation).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    getOrganisations(organisationId?: string): Observable<Organisation[]> {
        let uriString = `${this.idfAddress}/v1/organisations`;
        if (organisationId) {
            uriString.concat(`?organisationId=${organisationId}`);
        }
        return this.http.get(uriString).pipe(map((res: any) => res.result));
    }

    getESOrganisations(name: string): Observable<Organisation> {
        const uriString = `${this.idfAddress}/v1/elasticsearch/organisation?companyName=${name}`;
        return this.http.get(uriString).pipe(map((res: any) => res.result));
    }

    // Get stored Underwriter
    getUnderwriter(): Observable<Organisation> {
        const uriString = `${this.idfAddress}/v1/insurerUnderwritingConfigs/docs`;
        return this.http.get(uriString).pipe(map((res: any) => res.result));
    }
}
