import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeatureFlags } from '@pa/references/idf';
import { map, skipWhile, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RouteGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const path = route.url[0].path;
        return this.authService.authState
            .pipe(
                skipWhile((state) => !state || state === 'signIn'),
                map((state) => state === 'signIn'),
                switchMap(() => this.authService.featureFlags)
            )
            .pipe(
                map((flags) => {
                    const featureFlagTypes = flags?.map((flag) => flag.type) ?? [];
                    let allowRoute: boolean = false;

                    switch (path) {
                        case 'referrals':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerReferralManagement);
                            break;
                        case 'quotes-policies':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerCustomerServicing);
                            break;
                        case 'rate-management':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerRateManagementView);
                            break;
                        case 'distribution-management':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerDistributionManagement);
                            break;
                        case 'viewOrganisation':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerDistributionManagement);
                            break;
                        case 'viewQuote':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerCustomerServicing);
                            break;
                        case 'clientPolicy':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerCustomerServicing);
                            break;
                        case 'view-underwriting':
                            allowRoute = featureFlagTypes.includes(FeatureFlags.insurerRateVersioningView);
                            break;

                        default:
                            return true;
                    }

                    return allowRoute ? true : this.router.parseUrl('/');
                })
            );
    }
}
