import { Component, Input, OnInit } from '@angular/core';

import { pick } from 'lodash';

import { BehaviourService } from '@pa/lib-spa';
import { ExperienceType } from '@pa/references';
import {
    CoverageType,
    CoverageTypeDisplay,
    CurrencyCode,
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    openPilotWarranties,
    specialUses,
    StorageType,
    TransactionType,
    Use,
} from '@pa/references/idf';
import { displayIsoCode, iso3166 } from '@pa/references/iso3166';
import { AssetType, assetTypes, OperationType, operationTypeDisplayMap } from '@pa/references/paul-precision';
import { InsurerAssetTemplate } from '@pa/sdk/cmf';
import { AssetProposal, BehaviourUiConfig, HangarAddress, OpwExperience, UiConfig } from '@pa/sdk/idf';
import { Asset } from '@pa/sdk/rmf';

import { AssetModel } from '../../types';
import { displayRateVersion } from '../utils';

interface InfoDisplay {
    amendType?: string;
    model?: string;
    registration?: string;
    serialNumber?: string;
    location?: string;
    coverageType?: string;
    agreedValue?: string;
    opw?: string;
    uses?: string[];
    operations?: string[];
    passengerSeats?: number;
    crewSeats?: number;
    storageType?: string;
    rateVersion?: string[];
    mtom?: string;
    description?: string;
}

interface AdditionalDetails {
    type: Use;
    details: string[];
}

export const mapCoverageType = (coverageType: CoverageType, uiConfig: BehaviourUiConfig | undefined) => {
    if (uiConfig?.coverageTypes.length) {
        const coverage = uiConfig?.coverageTypes?.find((ct) => ct.type === coverageType);
        if (coverage) {
            return coverage?.display;
        }
    }
    return CoverageTypeDisplay[coverageType];
};

export const mapStorageType = (storageType?: StorageType, uiConfig?: BehaviourUiConfig): string | undefined => {
    if (uiConfig?.storageTypes) {
        return uiConfig.storageTypes.find((st) => st.type === storageType)?.display;
    }
    return storageType;
};

type AssetDisplayModelFields = Pick<
    AssetProposal,
    'type' | 'manufacturer' | 'model' | 'uavModel' | 'yearOfManufacture'
>;

@Component({
    selector: 'asset-basic-info',
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit {
    @Input() asset!: AssetModel;

    public assetType: AssetType | undefined;
    public info: InfoDisplay = {};
    public policyInfo: InfoDisplay | undefined = {};
    public showAmendType: boolean = false;
    public showExistingPolicy = false;

    public currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);

    constructor(private behaviourService: BehaviourService) {}

    ngOnInit() {
        this.assetType = this.asset.assetType;
        this.showAmendType = this.asset.transactionType !== TransactionType.newBusiness;
        this.showExistingPolicy = !!this.asset.assetPolicy && !!this.asset.existingAsset;

        const dynamicAssetTypes: AssetType[] = [AssetType.aircraft, AssetType.uav];

        this.behaviourService.behaviour.subscribe((b) => {
            const assetPolicy = this.asset.assetPolicy;
            const assetType = this.assetType;
            const currency = this.asset.currency;
            const existingAsset = this.asset.existingAsset;
            const insurerAssetTemplate = this.asset.insurerAssetTemplate;
            const opwExperience = this.asset.opwExperience;

            if (this.showExistingPolicy) {
                const manufacturer = existingAsset?.manufacturer ?? '';
                const model = existingAsset?.model ?? '';

                this.policyInfo = {
                    agreedValue: this._displayAgreedValue(currency, assetPolicy?.agreedValue ?? 0),
                    coverageType: this._displayCoverageType(assetPolicy?.coverageType, b.uiConfig),
                    crewSeats: existingAsset?.crewSeats,
                    location: this._displayLocation(assetPolicy?.hangarAddress),
                    model: this._displayModel({
                        type: assetPolicy?.appliesTo.type,
                        manufacturer: manufacturer,
                        model: model,
                        yearOfManufacture: existingAsset?.yearOfManufacture,
                    } as Asset),
                    operations: this._displayOperations(assetPolicy?.operations),
                    opw:
                        assetPolicy?.opw || assetPolicy?.opwExperience?.length
                            ? this._displayOpw(
                                  manufacturer,
                                  model,
                                  assetPolicy?.opwExperience ?? [],
                                  insurerAssetTemplate,
                                  assetPolicy.opw
                              )
                            : undefined,
                    passengerSeats: existingAsset?.passengerSeats,
                    registration: this._displayRegistration(
                        existingAsset?.serialNumber,
                        assetPolicy?.registrationCountry
                    ),
                    serialNumber: this._displayAircraftSerialNumber(
                        assetType,
                        existingAsset?.aircraftSerialNumber,
                        b.uiConfig
                    ),
                    uses: this._displayUses(
                        insurerAssetTemplate,
                        assetPolicy?.useTypes,
                        b.uiConfig,
                        assetPolicy?.useAdditionalDetails
                    ),
                    storageType: this._displayStorageType(assetPolicy?.storageType, b.uiConfig),
                    rateVersion: displayRateVersion(assetPolicy?.underwritingConfigVersions),
                    description: !dynamicAssetTypes.includes(existingAsset.type) ? existingAsset?.name : undefined,
                    mtom: this.getMtomWt(existingAsset),
                };
            }

            this.info = {
                amendType: this.asset.amendType,
                agreedValue: this._displayAgreedValue(currency, this.asset.agreedValue ?? 0),
                coverageType: this._displayCoverageType(this.asset.coverageType, b?.uiConfig),
                crewSeats: this.asset.crewSeats,
                location: this._displayLocation(this.asset.hangarAddress),
                model: this._displayModel({
                    ...pick(this.asset, ['manufacturer', 'model', 'uavModel', 'yearOfManufacture']),
                    type: assetType,
                }),
                operations: this._displayOperations(this.asset.operations, b.uiConfig),
                opw:
                    this.asset.opw || this.asset.opwType || opwExperience?.length
                        ? this._displayOpw(
                              this.asset.manufacturer ?? '',
                              this.asset.model ?? '',
                              opwExperience ?? [],
                              insurerAssetTemplate,
                              this.asset.opw,
                              this.asset.opwType
                          )
                        : undefined,
                passengerSeats: this.asset.passengerSeats,
                registration: this._displayRegistration(this.asset.serialNumber, this.asset.registrationCountry),
                serialNumber: this._displayAircraftSerialNumber(assetType, this.asset.aircraftSerialNumber, b.uiConfig),
                uses: this._displayUses(
                    insurerAssetTemplate,
                    this.asset?.useTypes,
                    b.uiConfig,
                    this.asset?.useAdditionalDetails
                ),
                storageType: this._displayStorageType(this.asset.storageType, b.uiConfig),
                rateVersion: displayRateVersion(this.asset.underwritingConfigVersions),
                mtom: this.getMtomWt(this.asset),
                description: !dynamicAssetTypes.includes(this.asset.type) ? this.asset.description : undefined,
            };
        });
    }

    infoTableClass() {
        return this.showExistingPolicy ? 'col-md-6' : 'col-md-12';
    }

    private _displayAgreedValue(currency: CurrencyCode, agreedValue: number) {
        const currencyFormatter = getCurrencyFormatter(currency);

        if (agreedValue > 0) {
            return formatCurrencyAmountByFormatter(agreedValue, currencyFormatter);
        }
    }

    private _displayAircraftSerialNumber = (
        assetType: AssetType,
        aircraftSerialNumber?: string,
        uiConfig?: BehaviourUiConfig
    ): string | undefined => {
        if (assetType === assetTypes.aircraft && uiConfig?.aircraftSerialNumber) {
            return aircraftSerialNumber;
        }
    };

    private _displayCoverageType(coverageType?: CoverageType, uiConfig?: BehaviourUiConfig) {
        if (coverageType) {
            return mapCoverageType(coverageType, uiConfig);
        }

        /* removing logic for drone condition
        else if (assetType === assetTypes.uav) {
            return agreedValue ? 'Hull and Liability' : 'Liability Only';
        }
        */
    }

    private _displayStorageType(storageType?: StorageType, uiConfig?: BehaviourUiConfig): string | undefined {
        return mapStorageType(storageType, uiConfig);
    }

    private _displayLocation(hangarAddress?: HangarAddress) {
        if (hangarAddress?.subdivision) {
            return [displayIsoCode(hangarAddress.subdivision), hangarAddress.postalCode].filter((v) => !!v).join(', ');
        }
    }

    private _displayModel(asset: AssetDisplayModelFields) {
        if (asset.type === assetTypes.uavEquipment || asset.type === assetTypes.uavPayload) {
            return;
        }
        let modelString = asset.yearOfManufacture
            ? `${asset.yearOfManufacture}, ${asset.manufacturer}`
            : `${asset.manufacturer}`;

        modelString += asset.type === assetTypes.uav ? ` ${asset.uavModel || asset.model}` : ` ${asset.model}`;

        return modelString;
    }

    private _displayOperations = (operations?: OperationType[], uiConfig?: UiConfig): string[] | undefined => {
        if (operations?.length) {
            return operations.map((o) => {
                const opDisplay = uiConfig?.operationTypes?.find((uio) => uio.type === o)?.display;
                return opDisplay ?? operationTypeDisplayMap[o];
            });
        }
    };

    private _displayOpw = (
        manufacturer: string,
        model: string,
        opwExperience: OpwExperience[],
        insurerAssetTemplate?: InsurerAssetTemplate,
        opw?: string,
        opwType?: string
    ): string | undefined => {
        const opwString = (flightTimeTotal?: number, flightTimeAsset?: number) =>
            `${flightTimeTotal} hours Total Time, ${flightTimeAsset} hours on ${manufacturer} ${model}`;
        const NPO_DISPLAY = 'Named Pilot Only';

        if (opwExperience?.length) {
            if (opwExperience.find((exp) => exp.type === ExperienceType.namedOnly)) {
                return NPO_DISPLAY;
            } else {
                const flightTimeTotal = opwExperience.find(
                    (exp) => exp.type === ExperienceType.journeyTimeTotal
                )?.journeyTime;
                const flightTimeAsset = opwExperience.find(
                    (exp) => exp.type === ExperienceType.journeyTimeAsset
                )?.journeyTime;
                if ([flightTimeTotal, flightTimeAsset].some((flightTime) => typeof flightTime !== 'number')) {
                    console.error('Unsupported opw experience', opwExperience);
                }
                return opwString(flightTimeTotal, flightTimeAsset);
            }
        } else {
            let opwConfig = insurerAssetTemplate?.opw?.find((o) => o._id === opw || o.type === opwType) ?? {
                _id: null,
                type: null,
            };

            const { type, flightTimeTotal, flightTimeAsset } = opwConfig;

            if (type === openPilotWarranties.namedPilot) {
                return NPO_DISPLAY;
            }

            return opwString(flightTimeTotal, flightTimeAsset);
        }
    };

    private _displayRegistration(serialNumber?: string, registrationCountry?: string) {
        let registration = '';
        if (registrationCountry) {
            registration += `${iso3166.find((country) => country.code === registrationCountry)?.name}, `;
        }

        if (serialNumber) {
            registration += serialNumber;
            return registration;
        }
    }

    private _displayUses(
        insurerAssetTemplate?: InsurerAssetTemplate,
        useTypes?: string[],
        uiConfig?: BehaviourUiConfig,
        useAdditionalDetails?: AdditionalDetails[]
    ) {
        if (!useTypes) return;

        // move 'Other' use to the end of the list
        const otherTypeUseIndex = useTypes?.findIndex((use) => use === Use.other || use === specialUses.other);
        if (typeof otherTypeUseIndex === 'number' && otherTypeUseIndex > -1) {
            const otherUse = useTypes[otherTypeUseIndex];
            useTypes.splice(otherTypeUseIndex, 1);
            useTypes.push(otherUse);
        }

        if (uiConfig?.uses) {
            return useTypes?.map((use) => {
                const displayUseName = uiConfig?.uses?.find((u) => use === u.type)?.display ?? use;
                const details = useAdditionalDetails?.find((useDetails) => useDetails.type === use)?.details.join(' ');

                if (details && details !== '') {
                    return `${displayUseName} - ${details}`;
                }

                return displayUseName;
            });
        } else {
            return useTypes?.map(
                (use) => insurerAssetTemplate?.uses?.find((u) => use === u.type)?.type.toString() ?? ''
            );
        }
    }

    getLabel(field: string, defaultValue?: string) {
        return this.asset.formConfig?.fields[field]?.label ?? defaultValue;
    }

    private getMtomWt(asset: Asset | AssetModel) {
        const unit = asset.type === AssetType.aircraft ? ' kg' : ' g';
        return asset.mtom ? asset.mtom + unit : undefined;
    }
}
