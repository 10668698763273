import { PolicyState } from '@pa/references/cmf';
import { TransactionType } from '@pa/references/idf';
import { ClientPolicy } from '@pa/sdk/cmf';

export function getTransactionType(clientPolicy: ClientPolicy) {
    let transactionType: TransactionType;
    switch (clientPolicy.policyState) {
        case PolicyState.cancelled:
            transactionType = TransactionType.cancellation;
            break;
        case PolicyState.expiringCancelled:
            transactionType = TransactionType.cancellation;
            break;
        case PolicyState.renewed:
            transactionType = TransactionType.renewal;
            break;
        case PolicyState.amended:
        case PolicyState.expiring:
        case PolicyState.expiringAmended:
            transactionType = TransactionType.amendment;
            break;
        case PolicyState.incepted:
        case PolicyState.expired:
        case PolicyState.lapsed:
        default:
            transactionType = TransactionType.newBusiness;
            break;
    }
    return transactionType;
}
