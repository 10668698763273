import moment from 'moment';

import { PolicyPeriod, TransactionType } from '@pa/references/idf';
import { ClientProposal } from '@pa/sdk/idf';

import { DATE_FORMAT } from '../const/const';
import { applyPolicyDateConfig } from '@pa/utils/temporal';

export const mapEffectiveDateLabel = (transactionType: TransactionType): string => {
    switch (transactionType) {
        case TransactionType.newBusiness:
        default:
            return 'Proposed Inception Date';
        case TransactionType.renewal:
            return 'Proposed Renewal Effective Date';
        case TransactionType.amendment:
            return 'Proposed Amendment Effective Date';
        case TransactionType.cancellation:
            return 'Proposed Cancellation Effective Date';
    }
};

export const mapProposalEffectiveDate = (proposal: ClientProposal): string | undefined => {
    switch (proposal.transactionType) {
        case TransactionType.newBusiness:
        default:
            return proposal.inceptionDate;
        case TransactionType.renewal:
            return proposal.renewalEffectiveDate;
        case TransactionType.amendment:
            return proposal.amendmentEffectiveDate;
        case TransactionType.cancellation:
            return proposal.cancellationEffectiveDate;
    }
};

export const formatDate = (date?: string, timezone?: string, policyPeriod?: PolicyPeriod): string => {
    if (!date) {
        return '';
    }

    // Apply policy date format using behaviour configuration if available
    if (policyPeriod) {
        return applyPolicyDateConfig(date, policyPeriod, timezone);
    }

    // Fall back to default date format
    const utcTime = moment.utc(date);

    let localTime = utcTime.clone();
    if (timezone) {
        localTime = localTime.tz(timezone);
    }

    return localTime.format(DATE_FORMAT);
};
