import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { isPinnedBottom } from '../../../underwriting/utils/ag-grid';

type ICellRendererParamsExt = ICellRendererParams & { onEdit: () => void; onDelete: () => void };

@Component({
    selector: 'lp-edit-cell',
    templateUrl: './edit-cell.component.html',
    styleUrls: ['./edit-cell.component.scss'],
})
export class EditCellComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsExt = {} as ICellRendererParamsExt;
    enableEdit: boolean = false;
    enableDelete: boolean = false;
    testId: string;

    agInit(params: ICellRendererParamsExt): void {
        this.params = params;
        this.enableEdit = !!params.onEdit && !isPinnedBottom(params);
        this.enableDelete = !!params.onDelete;
        this.testId = `edit-asset-${params.data.serialNumber}`;
    }

    refresh = (params: any): boolean => true;

    onEditClick() {
        this.params.onEdit();
    }

    onDeleteClick() {
        this.params.onDelete();
    }
}
