import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetModel } from '../types';

@Component({
    selector: 'payload-info',
    templateUrl: './payload-info.component.html',
})
export class PayloadInfoComponent implements OnInit {
    @Input() asset: AssetModel;
    @Output() validated = new EventEmitter<boolean>();

    premiumsValid: boolean = false;

    ngOnInit() {}

    onPremiumsValidated(isValid: boolean) {
        this.premiumsValid = isValid;
        this.validate();
    }

    validate(): void {
        this.validated.emit(this.premiumsValid);
    }
}
