import { AbstractControl } from '@angular/forms';
import { Endorsement } from '@pa/sdk/cmf';
import { ClientQuote, QuotePriceInput } from '@pa/sdk/idf';

export function policyEndorsementsValidator(model: any) {
    return (control: AbstractControl): any => {
        const nonZeroEndorsements = (model.clientQuote as ClientQuote)?.priceYear
            .filter((p) => (p.hull ?? p.liability ?? p.standard) > 0)
            .map((p) => p.type);

        const invalid = control.value
            ?.filter((e: Endorsement) => nonZeroEndorsements.includes(e.type))
            .some((endorsement: Endorsement) => {
                let priceYear = model.clientQuote?.priceYearAdjusted?.find((pya: QuotePriceInput) => pya.type === endorsement.type);
                if (!priceYear) {
                    priceYear = model.clientQuote?.priceYear?.find((pya: QuotePriceInput) => pya.type === endorsement.type);
                }
                const premium = priceYear?.liability ?? priceYear?.standard;
                return (premium ?? null) === null;
            });
        return invalid ? { 'clientQuote.assetQuotes': true } : null;
    };
}