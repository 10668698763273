<div>
    <h1>Claims Management</h1>
    <mat-card class="my-3 px-3">
        <div class="row">
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title> Policy Details </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row justify-content-center" *ngIf="loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <table class="mat-table" *ngIf="!loading">
                        <tr class="mat-row">
                            <th class="mat-header-cell">Insured Name:</th>
                            <td class="mat-cell">{{ basicInfo?.companyName }}</td>
                        </tr>
                        <tr class="mat-row">
                            <th class="mat-header-cell">Policy Reference:</th>
                            <td class="mat-cell">{{ basicInfo?.reference }}</td>
                        </tr>
                        <tr class="mat-row">
                            <th class="mat-header-cell">Period of Insurance:</th>
                            <td class="mat-cell">{{ basicInfo?.renewalDate }} - {{ basicInfo?.expiryDate }}</td>
                        </tr>
                        <tr class="mat-row">
                            <th class="mat-header-cell">Date of Loss:</th>
                            <td class="mat-cell">{{ basicInfo?.dateOfLoss }}</td>
                        </tr>
                        <ng-template [ngIf]="basicInfo?.contact">
                            <tr class="mat-row">
                                <th class="mat-header-cell">Contact Name:</th>
                                <td class="mat-cell">{{ basicInfo?.contact?.name }}</td>
                            </tr>
                            <tr class="mat-row">
                                <th class="mat-header-cell">Contact Email:</th>
                                <td class="mat-cell">{{ basicInfo?.contact?.emailAddress }}</td>
                            </tr>
                            <tr class="mat-row">
                                <th class="mat-header-cell">Contact Phone Number:</th>
                                <td class="mat-cell">{{ basicInfo?.contact?.phoneNumber }}</td>
                            </tr>
                        </ng-template>
                        <br />
                        <tr class="mat-row">
                            <th class="mat-header-cell">FNOL Documentation:</th>
                            <td class="mat-cell">
                                <a href="{{ basicInfo?.fnolDocument.url }}" target="_blank">
                                    {{ basicInfo?.fnolDocument.display }}
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title> Policy Documentation Links </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row justify-content-center" *ngIf="loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <table class="mat-table" *ngIf="!loading">
                        <li *ngFor="let attachment of basicInfo?.policyDocuments">
                            <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
                        </li>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title> Images and Files </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row justify-content-center" *ngIf="loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <table class="mat-table" *ngIf="!loading">
                        <li *ngFor="let eachFile of basicInfo?.documents">
                            <a [href]="eachFile.url" target="_blank"> {{ eachFile.display }}</a>
                        </li>
                    </table>
                </mat-card-content>
            </div>
        </div>
    </mat-card>
</div>
