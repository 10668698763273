import { Component, OnInit } from '@angular/core';
import { IdfService } from '../services/idf.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-view-underwriting',
  templateUrl: './view-underwriting-configurations.component.html',
  styleUrls: ['./view-underwriting-configurations.component.scss']
})
export class ViewUnderwritingComponent implements OnInit {
  error: boolean = false;
  loading: boolean = true;
  documentationLinks
  documentationArray
  constructor(private idfService: IdfService,private router: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.loading = true;
    this.router.queryParams.pipe(
      switchMap(() =>{
        return this.idfService.getUnderwriter();
      })
    ).subscribe({
      next:async (underwriter)=>{
        this.documentationArray = underwriter;
        this.documentationLinks = this.documentationArray.documentationLinks;
        this.loading = false;

      },error: (err) => {
        this.loading = false;
        this.error = true;
      }

    })

  }

}
