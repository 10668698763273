<div [ngClass]="themeName">
    <div hidden="true">Version {{ version }}</div>
    <app-nav *ngIf="appReady"></app-nav>
    <ngx-spinner
        *ngIf="!appReady"
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#f4696b"
        type="pacman"
    ></ngx-spinner>
</div>
