import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { omit, pick } from 'lodash';

import { Auth } from 'aws-amplify';
import { combineLatest, forkJoin, from, of, Subject, throwError } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

import { BehaviourService } from '@pa/lib-spa';

import { NotificationService } from '../../common/notification.service';
import { AuthService } from '../../core/auth/auth.service';
import { SdkService } from '../../services/sdk.service';

import {
    AssetPowerSource,
    AssetTemplateNew,
    AssetType,
    assetTypes,
    gearTypeDisplay,
    liftTypeDisplay,
    ProductType,
    productTypes,
} from '../rate-management.types';

import { getAddAssetTemplateFormConfig } from './form-configs';
import { Behaviour } from 'src/app/claims/claims.types';
import { InsurerAssetTemplateNew } from '@pa/sdk/cmf';

const assetDisplayMap: { [P in ProductType]: string } = {
    [productTypes.aviation]: 'Aircraft',
    [productTypes.drone]: 'UAV',
};

const assetTypeMap: { [P in ProductType]: AssetType } = {
    [productTypes.aviation]: assetTypes.aircraft,
    [productTypes.drone]: assetTypes.uav,
};

@Component({
    selector: 'cp-add-asset-template-page',
    styleUrls: ['add-asset-template.page.scss'],
    templateUrl: './add-asset-template.page.html',
})
export class AddAssetTemplatePage implements OnInit {
    @ViewChild('summaryDialog', { static: false }) summaryDialog: TemplateRef<any> | undefined;

    public assetDisplay: string;
    public readonly booleanDisplay = (value: boolean) => (value ? 'Yes' : 'No');
    public readonly gearTypeDisplay = gearTypeDisplay;
    public readonly liftTypeDisplay = liftTypeDisplay;
    public fields: FormlyFieldConfig[] = [];
    public form: FormGroup = new FormGroup({});
    public loadingForm = true;
    public model: any = {};
    public processingSubmission: boolean = false;
    public summaryDialogRef: MatDialogRef<any> | undefined;

    private _behaviour: Behaviour;

    constructor(
        public dialog: MatDialog,
        private _route: ActivatedRoute,
        private ngZone: NgZone,
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _notificationService: NotificationService,
        private _sdk: SdkService
    ) {}

    ngOnInit() {
        this.loadingForm = true;
        this._route.queryParams
            .pipe(
                concatMap((params) => {
                    const behaviour = params.behaviour;
                    if (!behaviour) {
                        return of(undefined as undefined);
                    }
                    return combineLatest([
                        this._behaviourService.getAndSetNewBehaviour(behaviour),
                        this._authService.userGroups,
                    ]);
                })
            )
            .subscribe({
                next: (res) => {
                    this.fields = [];
                    if (res) {
                        const [behaviour, groups] = res;

                        this._behaviour = behaviour;
                        this.assetDisplay = assetDisplayMap[behaviour.product];
                        this.loadingForm = false;

                        if (groups.includes('hdi.drone') || groups.includes('pa-test.drone')) {
                            this.fields = getAddAssetTemplateFormConfig(behaviour);
                        }
                    }
                },
            });
    }

    openSummaryDialog() {
        if (this.summaryDialog) {
            this.ngZone.run(() => {
                this.summaryDialogRef = this.dialog.open(this.summaryDialog, {});
            });
        }
    }

    closeSummaryDialog() {
        this.summaryDialogRef?.close();
    }

    submit() {
        this.processingSubmission = true;
        from(Auth.currentSession())
            .pipe(
                concatMap((userSession) => {
                    this._sdk.cmf.accessToken = userSession.getAccessToken().getJwtToken();
                    this._sdk.rmf.accessToken = userSession.getAccessToken().getJwtToken();

                    return this._sdk.cmf.InsurerAssetTemplates.get(this._behaviour._id, {
                        assetTemplate: this.model.existingAssetTemplate,
                    });
                }),
                concatMap((iats) => {
                    if (!iats.length) {
                        throw new Error('No existing IAT found');
                    }
                    const pickKeys = [
                        'defaultCrewSeats',
                        'defaultPaxSeats',
                        'defaultMtom',
                        'gearType',
                        'liftType',
                        'manufacturer',
                        'model',
                        'mountable',
                        'weight',
                    ];
                    let powerSources: AssetPowerSource[] | undefined;
                    if (this.model.powerSourceType && this.model.powerSourceFuelType) {
                        powerSources = [
                            { fuelType: this.model.powerSourceFuelType, type: this.model.powerSourceFuelType },
                        ];
                    }
                    const newAssetTemplate: AssetTemplateNew = {
                        type: assetTypeMap[this._behaviour.product],

                        ...pick(this.model, pickKeys),
                        powerSources,
                    };

                    return forkJoin([
                        of(iats[0]),
                        this._sdk.rmf.AssetTemplates.create(this._behaviour._id, [newAssetTemplate]),
                    ]);
                }),
                concatMap((res) => {
                    const iat = res[0];
                    const assetTemplate = res[1][0];

                    const newIat: InsurerAssetTemplateNew = Object.assign(omit(iat, ['_id']), {
                        assetTemplate: assetTemplate._id,
                        behaviours: [this._behaviour._id],

                        assetReference: this.model.assetReference,
                    });

                    return this._sdk.cmf.InsurerAssetTemplates.create(this._behaviour._id, [newIat]);
                }),
                take(1) // Complete the Observable immediately
            )
            .subscribe({
                next: () => {
                    this.processingSubmission = false;
                    this.closeSummaryDialog();
                    this.form.reset();
                    this._notificationService.success(`New ${this.assetDisplay} template has been added`);
                },
                error: (err) => {
                    this.processingSubmission = false;
                    const errMsg = [
                        `Could not add ${this.assetDisplay} due to ${err}.`,
                        'Please try again or contact support@pa.com',
                    ].join(' ');
                    this._notificationService.error(errMsg);
                },
            });
    }
}
