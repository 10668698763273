import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../core/auth/auth.service';

declare const hbspt: any

@Component({
  selector: 'app-rate-management',
  templateUrl: './rate-management.component.html',
  styleUrls: ['./rate-management.component.scss']
})
export class RateManagementComponent implements OnInit, AfterViewInit {

  private _addAssetForm: any;
  public showForms: Observable<boolean>;

  constructor(private _authService: AuthService) { }

  ngOnInit() {
    this.showForms = this._authService.userGroups.pipe(
      map(groups => {
        return groups.includes('hssb');
      })
    )
  }

  ngAfterViewInit() {
    if (!this._addAssetForm) {
      this._addAssetForm = hbspt.forms.create({
        region: 'na1',
        portalId: '5189173',
        formId: 'ac24253d-2752-4f91-9883-a5ae206a3ba0',
        target: '#addAssetsForm'
      });
    }

  }
}