import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviourService } from '@pa/lib-spa';
import { Behaviour } from '@pa/sdk/idf';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';
import { AuthService } from '../core/auth/auth.service';
import { IdfService } from '../services/idf.service';
import { QuoteService } from '../services/quote.service';
import { AddOrganisation } from './dialog/add-organisation/add-organisation';
import { AddOriginator } from './dialog/add-originator/add-originator';

@Component({
    selector: 'app-distribution-management',
    templateUrl: './distribution-management.component.html',
    styleUrls: ['./distribution-management.component.scss'],
})
export class DistributionManagementComponent implements OnInit {
    @ViewChild('organisationDialog') organisationDialog: AddOrganisation;
    @ViewChild('originatorDialog') originatorDialog: AddOriginator;

    public organisationSearchQuery: string = window.sessionStorage.getItem('organisation') ?? '';

    public model: any = {};
    public options = {};
    public form = new FormGroup({});
    public fields: FormlyFieldConfig[] = [
        {
            key: 'organisation',
            type: 'input',
            defaultValue: this.organisationSearchQuery,
            templateOptions: {
                type: 'text',
                attributes: {
                    'data-testid': 'search-organisation',
                },
                label: 'Search Organisation',
                required: true,
                pattern: '[A-Za-z0-9 ]+',
                minLength: 3,
                placeholder: 'Enter Organisation name',
            },
        },
    ];

    public organisationGridOptions: GridOptions = {
        columnDefs: [
            {
                headerName: 'Organisation Name',
                field: 'organisationName',
                flex: 1,
            },
        ],
        rowHeight: 48,
        overlayNoRowsTemplate: '<span>No Organisations are there to show</span>',
        // TODO: Implement functionallity to pass the data to /viewOrganisation to show the originator details
        onRowClicked: (event: RowClickedEvent) => {
            this.router.navigate([`/viewOrganisation?id=`]);
        },
    };
    public organisations = [];
    public behaviours: Behaviour[] = [];
    public currentBehaviour: Behaviour | undefined;
    public selectedBehaviour: Behaviour | undefined;

    public behavioursLoading = true;
    showPage: boolean = false;

    constructor(
        public behaviourService: BehaviourService,
        private idfService: IdfService,
        private quoteService: QuoteService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.showPage = true;
        this.organisations = (await this.idfService.getOrganisations().toPromise()).map((o) => ({
            organisationName: o.name,
        }));
        if (this.organisationSearchQuery) {
            await this.searchOrganisation();
        }
        this.quoteService.getBehavioursfromCognito().subscribe((behaviours) => {
            this.behaviours = behaviours;
            this.behavioursLoading = false;
        });
    }

    public resizeGrids(): void {
        this.organisationGridOptions.api?.sizeColumnsToFit();
    }

    // TODO: Get organisations for the selected behaviour and then update it to the table
    public async searchOrganisation(): Promise<void> {
        //@ts-ignore
        const { organisation } = this.form.getRawValue();
        window.sessionStorage.setItem('organisation', organisation);
        const org = await this.idfService.getESOrganisations(organisation).toPromise();
    }

    public openOriginatorModal(): void {
        this.originatorDialog.open();
    }

    public openOrganisationModal(): void {
        this.organisationDialog.open();
    }

    public updateCurrentBehaviour(behaviour: Behaviour) {
        this.currentBehaviour = behaviour;
        this.behaviourService.set(behaviour);
    }

    public selectBehaviour() {
        this.selectedBehaviour = this.currentBehaviour;
    }
}
