<ng-template #originatorDialog>
    <!-- Show loader -->
    <mat-dialog-content id="loader" class="justify-content-center" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </mat-dialog-content>

    <!-- Add Originator -->
    <mat-dialog-content *ngIf="showAddOriginator">
        <h2 data-testid="add-originator">Add Originator</h2>
        <form id="originator" class="col-12" [formGroup]="form">
            <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center" *ngIf="showAddOriginator">
        <div class="text-center mt-4 mb-1">
            <button mat-flat-button type="button" (click)="close()" data-testid="originator-dialog-close-button">
                Close
            </button>
            <button
                mat-raised-button
                class="ml-2 btn btn-primary"
                type="button"
                [disabled]="form.invalid"
                (click)="addOriginator()"
                data-testid="originator-dialog-confirm-button"
            >
                Add
            </button>
        </div>
    </mat-dialog-actions>

    <!-- Show Added Originator -->
    <mat-dialog-content *ngIf="showOriginator">
        <h2>Broker</h2>
        <table class="mat-table">
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Onboard status</th>
                <td class="mat-cell">Success</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Broker Name</th>
                <td class="mat-cell">{{ originator.individual }}</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Broker Originator Link</th>
                <td class="mat-cell">{{ originator.link }}</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Organisation Landing URL</th>
                <td class="mat-cell">{{ originator.landingUrl }}</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Broker Pin</th>
                <td class="mat-cell">{{ originator.pin }}</td>
            </tr>
        </table>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center" *ngIf="showOriginator">
        <button type="button" mat-flat-button class="justify-content-center" (click)="close()">close</button>
    </mat-dialog-actions>

    <!-- Show Error -->
    <mat-dialog-content *ngIf="error">
        <p class="text-center">
            Looks like an error occurred - please try again or contact us at
            <a href="mailto://support@precision-autonomy.com">support@precision-autonomy.com</a>
        </p>
    </mat-dialog-content>
</ng-template>
