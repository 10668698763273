import { GridOptions, ValueFormatterParams } from 'ag-grid-community';

export const feesGridOptions = (valueFormatter: (params: ValueFormatterParams) => string) => {
    const options: GridOptions = {
        defaultColDef: {
            resizable: true,
            width: 100,
        },
        columnDefs: [
            {
                headerName: 'Fee',
                cellStyle: {
                    'text-transform': 'capitalize',
                },
                valueFormatter: (params) => params.data.type + ' Fee',
            },
            {
                headerName: 'Amount',
                field: 'value',
                editable: true,
                valueFormatter,
            },
        ],
        singleClickEdit: true,
        animateRows: true,
    };
    return options;
};
