export const environment = {
    "production": false,
    "serviceName": "CoverPortal",
    "env": "stage",
    "amplify": {
        "Auth": {
            "mandatorySignIn": true,
            "region": "us-west-2",
            "userPoolId": "us-west-2_zHkJq1vkN",
            "identityPoolId": "",
            "userPoolWebClientId": "1s9ofgc1ol4daeer8sl1tct7a7",
            "cookieStorage": {
                "domain": ".pastage.cloud",
                "secure": true,
                "path": "/",
                "expires": 7,
                "sameSite": "lax"
            },
            "oauth": {
                "domain": "auth.pastage.cloud",
                "redirectSignIn": "https://cover.pastage.cloud/",
                "redirectSignOut": "https://cover.pastage.cloud/",
                "responseType": "code"
            }
        }
    },
    "apiBaseUrls": {
        "cmf": "https://cmf.pastage.cloud/api",
        "rmf": "https://rmf.pastage.cloud/api",
        "idf": "https://idf.pastage.cloud/api",
        "pay": "https://pay.pastage.cloud/api",
        "loginPortal": "https://login.pastage.cloud"
    }
}