<div class="text-right">
    <button
        *ngIf="enableEdit"
        mat-flat-button
        title="Edit"

        (click)="onEditClick()"
        color="primary"
        [attr.data-testid]="testId"
    >
        <i class="fal fa-pencil"></i>
        <span class="pl-2" class="hide-under-{{ this.params.showLabelsAtSize }}">Edit</span>
    </button>
    <button
        *ngIf="enableDelete"
        class="ml-1 btn-delete"
        mat-button
        title="Delete"
        color="warn"
        (click)="onDeleteClick()"
    >
        <i class="fas fa-trash"></i>
        <span class="pl-2" class="hide-under-{{ this.params.showLabelsAtSize }}">Remove</span>
    </button>
</div>
