/**
 * FIXME: These utils are copied from IDF. Consider abstracting both copies into a shared lib.
 */
import { round } from 'lodash';
import { QuotePriceInput } from '@pa/sdk/idf';
import { AmendType } from '@pa/references/idf';
import { isPinnedBottom } from '../../utils/ag-grid';
import { QuoteUnderwritingConfig } from '@pa/sdk/cmf';

const RATE_PRECISION = 4;
const PRICE_PRECISION = 2;

export function adjustPrices(
    quotePrice: QuotePriceInput,
    policyPrice: QuotePriceInput,
    agreedValue?: number,
    amendType?: AmendType
) {
    if (amendType === AmendType.removed) {
        return negatePrice(quotePrice, agreedValue);
    } else {
        return diffPrice(quotePrice, policyPrice, agreedValue);
    }
}

export function applyDiscount(premium: number = 0, discountRate: number = 0) {
    return round(premium * (1 - discountRate), PRICE_PRECISION);
}

export function diffPrice(
    quotePrice: QuotePriceInput,
    policyPrice: QuotePriceInput,
    agreedValue?: number
): QuotePriceInput {
    const adjustedPrice: QuotePriceInput = { ...quotePrice };

    if (typeof quotePrice.hull === 'number') {
        adjustedPrice.hull = quotePrice.hull - (policyPrice.hull ?? 0);
        if (agreedValue) {
            adjustedPrice.rate = round(adjustedPrice.hull / agreedValue, RATE_PRECISION);
        } else {
            adjustedPrice.rate = 0;
        }
    }
    if (typeof quotePrice.liability === 'number') {
        adjustedPrice.liability = quotePrice.liability - (policyPrice.liability ?? 0);
    }
    if (typeof quotePrice.standard === 'number') {
        adjustedPrice.standard = quotePrice.standard - (policyPrice.standard ?? 0);
    }

    return adjustedPrice;
}

export function negatePrice(price: QuotePriceInput, agreedValue: number): QuotePriceInput {
    const adjustedPrice: QuotePriceInput = { ...price };
    if (adjustedPrice.hull) {
        adjustedPrice.hull = -adjustedPrice.hull;
        adjustedPrice.rate = round(adjustedPrice.hull / agreedValue, RATE_PRECISION);
    }
    if (adjustedPrice.liability) {
        adjustedPrice.liability = -adjustedPrice.liability;
    }
    if (adjustedPrice.standard) {
        adjustedPrice.standard = -adjustedPrice.standard;
    }

    return adjustedPrice;
}

export function getRowStyle(params: any) {
    let style = { width: '100%' };
    if (isPinnedBottom(params)) {
        style['font-weight'] = 'bold';
    }
    return style;
}

export function displayRateVersion(underwritingConfigVersions: QuoteUnderwritingConfig[] | undefined) {
    if (underwritingConfigVersions?.length) {
        return underwritingConfigVersions.map(
            ({ type, version }) => `${type.charAt(0).toUpperCase() + type.slice(1)}: ${version}`
        );
    }
}
