import { ValueFormatterParams } from 'ag-grid-community';

import _ from 'lodash';
import {
    CurrencyCode,
    formatCurrencyAmountByFormatter,
    InterestedParty,
    InterestedPartyNotedOn,
    productTypes,
    getCurrencyFormatter as getCurrencyFormatterFromCode,
} from '@pa/references/idf';
interface CustomNumberFormatOptions extends Intl.NumberFormatOptions {
    currencyDisplay?: string;
}
export const getCurrencyFormatter = (currencyCode?: CurrencyCode) =>
    currencyCode
        ? getCurrencyFormatterFromCode(currencyCode)
        : new Intl.NumberFormat('en-AU', {
              style: 'currency',
              currency: CurrencyCode.AUD,
              currencyDisplay: 'code',
          } as CustomNumberFormatOptions);

export const getAgGridCurrencyFormatter = (currency: CurrencyCode) => (params: ValueFormatterParams) => {
    if (!params.value) {
        return '';
    }
    return formatCurrencyAmountByFormatter(params.value, getCurrencyFormatter(currency));
};

export const agGridCurrencyFormatterInFormly = (params: ValueFormatterParams) =>
    getAgGridCurrencyFormatter(params.context.parentField.templateOptions.outputObs?.currencySelected?.value)(params);

export const interestedPartyTypeMapping = new Map<InterestedParty, string>([
    [InterestedParty.financialInterest, 'Financial Interest'],
    [InterestedParty.additionalInsured, 'Additional Insured'],
    [InterestedParty.firstLossPayee, 'First Loss Payee'],
    [InterestedParty.ftrrai, 'For Their Respective Rights & Interests'],
    [InterestedParty.operator, 'Operator'],
    [InterestedParty.aocHolder, 'AOC Holder'],
    [InterestedParty.additionalInsured30DayNocCA, 'Additional Insured 30 Day NOC CA'],
    [InterestedParty.additionalInsuredCA, 'Additional Insured CA'],
    [InterestedParty.additionalInsuredExclManufacturerCA, 'Additional Insured Excluded Manufacturer CA'],
    [InterestedParty.additionalInsuredSubrogationWaiverCA, 'Additional Insured Subrogation Wavier CA'],
]);

export const interestedPartyTypeFormatter = (params: ValueFormatterParams) =>
    interestedPartyTypeMapping.get(params.value) || '';

export const interestedPartyNotedOnMapping = new Map<InterestedPartyNotedOn, string>([
    [InterestedPartyNotedOn.client, 'Policy Wide'],
    [InterestedPartyNotedOn.asset, 'Specific Aircraft'],
    // [InterestedPartyNotedOn.assetAndClient, 'Both'],
]);

export const interestedPartyNotedOnFormatter = (params: ValueFormatterParams) =>
    interestedPartyNotedOnMapping.get(params.value) || '';

export const getInterestedPartyNotedOnFormatter = (aircraftModel: any) => (params: ValueFormatterParams) => {
    const findInterstedPartySerialNumbers = () =>
        aircraftModel
            .filter((aircraft) => aircraft.interestedParties?.includes(params.data._idTemp))
            .map((aircraft) => aircraft.serialNumber);

    switch (params.value) {
        case InterestedPartyNotedOn.client:
            return interestedPartyNotedOnMapping.get(params.value);
        case InterestedPartyNotedOn.asset:
            return findInterstedPartySerialNumbers().join(', ');
        case InterestedPartyNotedOn.assetAndClient:
            return [
                interestedPartyNotedOnMapping.get(InterestedPartyNotedOn.client),
                ...findInterstedPartySerialNumbers(),
            ].join(', ');
    }
};
