<ng-template #organisationDialog>
    <!-- Loading screen -->
    <mat-dialog-content id="loader" class="justify-content-center" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </mat-dialog-content>

    <!-- Add Organisation -->
    <mat-dialog-content *ngIf="showAddOrganisation">
        <h2 data-testid="add-organisation">Add Organisation</h2>
        <form id="organisation" class="col-12" [formGroup]="form">
            <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
        </form>
        <ag-grid-angular
            class="col-12"
            domLayout="autoHeight"
            class="ag-theme-material"
            [rowData]="mappedBehaviours"
            [gridOptions]="behaviourGridOptions"
            (columnResized)="resizeGrids()"
        ></ag-grid-angular>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center" *ngIf="showAddOrganisation">
        <div class="text-center mt-4 mb-1">
            <button mat-flat-button type="button" (click)="close()" data-testid="organisation-dialog-close-button">
                Close
            </button>
            <button
                mat-raised-button
                class="ml-2"
                type="button"
                [disabled]="form.invalid"
                (click)="addOrganisation()"
                data-testid="organisation-dialog-confirm-button"
            >
                Add
            </button>
        </div>
    </mat-dialog-actions>

    <!--  Show Added Organisation -->
    <mat-dialog-content *ngIf="showOrganisation">
        <h2>Organisation</h2>
        <table class="mat-table">
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Onboard status</th>
                <td class="mat-cell">Success</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Organisation Name</th>
                <td class="mat-cell">{{ organisation.name }}</td>
            </tr>
            <tr class="mat-row">
                <th class="mat-header-cell col-3">Organsiation Landing URL</th>
                <td class="mat-cell">{{ organisation.landingUrl }}</td>
            </tr>
        </table>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center" *ngIf="showOrganisation">
        <button type="button" mat-flat-button class="justify-content-center" (click)="close()">close</button>
    </mat-dialog-actions>

    <!-- Show Error -->
    <mat-dialog-content *ngIf="error">
        <p class="text-center">
            Looks like an error occurred - please try again or contact us at
            <a href="mailto://support@precision-autonomy.com">support@precision-autonomy.com</a>
        </p>
    </mat-dialog-content>
</ng-template>
