import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDF_ADDRESS, RMF_ADDRESS } from '@pa/lib-spa';

import { IdfResponse, IncidentReport, PaClient, RmfResponse } from './claims.types';
import { ClaimsServiceModule } from './claims.service.module';

@Injectable({
    providedIn: ClaimsServiceModule,
})
export class ClaimsService {
    constructor(
        @Inject(RMF_ADDRESS) private _rmfAddress: string,
        @Inject(IDF_ADDRESS) private _idfAddress: string,
        private _http: HttpClient
    ) {}

    getClient(behaviourId: string, paClientId: string): Observable<PaClient> {
        return this._http
            .get<RmfResponse<PaClient>>(`${this._rmfAddress}/v1/paClients/${paClientId}?&behaviour=${behaviourId}`)
            .pipe(map((res) => res.result));
    }

    getIncidentReport(behaviourId: string, incidentReportId: string): Observable<IncidentReport> {
        return this._http
            .get<IdfResponse<IncidentReport>>(
                `${this._idfAddress}/v1/incidentReports/${incidentReportId}?behaviour=${behaviourId}`
            )
            .pipe(map((res) => res.result as IncidentReport));
    }
}
