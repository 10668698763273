import { GearType, gearTypes, LiftType, liftTypes } from '@pa/references/rmf';

export { ProductType, productTypes } from '@pa/references/idf';
export { AssetType, assetTypes } from '@pa/references/paul-precision';
export { AssetPowerSource, AssetTemplateNew } from '@pa/sdk/rmf';

// TODO: Move to references
export const gearTypeDisplay: { [G in GearType]: string } = {
    [gearTypes.amphibious]: 'Amphibious',
    [gearTypes.fixed]: 'Fixed',
    [gearTypes.floats]: 'Floats',
    [gearTypes.retractable]: 'Retractable',
    [gearTypes.taildragger]: 'Taildragger',
};

export const liftTypeDisplay: { [L in LiftType]: string } = {
    [liftTypes.fixedWing]: 'Fixed Wing',
    [liftTypes.rotary]: 'Rotary',
};
