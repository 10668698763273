import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { DashboardPage } from './core/dashboard/dashboard.page';
import { UnderwritingComponent } from './underwriting/underwriting.component';
import { ClientQuoteComponent } from './client-quotes/client-quote.component';
import { DistributionManagementComponent } from './distribution-management/distribution-management.component';
import { ViewOrganisationComponent } from './view-organisation/view-organisation.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';
import { ViewPolicyComponent } from './view-policy/view-policy.component';
import { ViewUnderwritingComponent } from './view-underwriting-configurations/view-underwriting-configurations.component';
import { QuotePolicyHomePageComponent } from './quote-policy-home-page/quote-policy-home-page.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { RateManagementComponent } from './rate-management/rate-management.component';
import { RouteGuard } from './core/route/route.guard';

const routes: Routes = [
    { path: '', component: DashboardPage, canActivate: [AuthGuard] },
    {
        path: 'underwriting',
        component: UnderwritingComponent,
    },
    {
        path: 'clientQuote',
        component: ClientQuoteComponent,
    },
    {
        path: 'viewOrganisation',
        component: ViewOrganisationComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'viewQuote',
        component: ViewQuoteComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'clientPolicy',
        component: ViewPolicyComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'referrals',
        component: ReferralsComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'quotes-policies',
        component: QuotePolicyHomePageComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'rate-management',
        component: RateManagementComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'distribution-management',
        component: DistributionManagementComponent,
        canActivate: [RouteGuard],
    },
    {
        path: 'view-underwriting',
        component: ViewUnderwritingComponent,
        canActivate: [RouteGuard],
    },
    {
        //Any unknown path, redirectTo dashboard page
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
