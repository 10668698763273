<h1>Dashboard</h1>

<div *ngIf="loading" class="row justify-content-center my-3">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<gridster [options]="options" *ngIf="!loading">
    <gridster-item [item]="item" *ngFor="let item of dashboard">
        <div class="container grid-container">
            <div class="grid-heading m-2">
                <span class="h5">{{ item.heading }}</span>
            </div>
            <div class="grid-content">
                {{ item.content }}
            </div>
            <div class="grid-footer row justify-content-center">
                <button class="btn-update btn-primary btn-sm grid-button" mat-flat-button color="primary" routerLink="{{item.link}}"
                    [attr.data-testid]="item.link">
                    {{ item.heading }}<i class="fad fa-external-link ml-2"></i>
                </button>
            </div>
        </div>
    </gridster-item>
</gridster>