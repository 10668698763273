import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private _snackBar: MatSnackBar) {}

    success(message: string) {
        this.showMessage(message, 'success-snackbar');
    }
    warn(message: string) {
        this.showMessage(message, 'warning-snackbar');
    }

    error(message: string) {
        this.showMessage(message, 'error-snackbar');
    }

    showMessage(message: string, panelClass: string) {
        this._snackBar.open(message, 'X', {
            duration: 3000,
            panelClass,
        });
    }
}
