import addressFormatter from '@fragaria/address-formatter';
import { iso3166 } from '@pa/references/iso3166';
import { AddressField, AddressFormat, ProposedClient, UiConfig } from '@pa/sdk/idf';
export function mapClientAddress(
    company: ProposedClient,
    uiConfigAddressFormat?: UiConfig['addressFormat'],
    oneLine = false
) {
    const { subdivision: stateCode, country: countryCode } = company.address;
    const country = iso3166.find((c) => c.code === countryCode);
    const state = country.subdivisions.find((s) => s.code === stateCode);

    const defaultAddressFormat = {
        streetAddress: 'address1 address2',
        city: 'city',
        postalCode: 'postalCode',
        subdivision: 'subdivision',
    };
    const addressFormat = uiConfigAddressFormat ?? defaultAddressFormat;

    const formattedAddressFormat: AddressFormat = {};
    Object.entries(addressFormat).forEach(([key, value]: [AddressField, string]) => {
        formattedAddressFormat[key] = value
            .split(' ')
            .map((word) => company.address[word])
            .join(' ');
    });
    formattedAddressFormat.country = country.name;
    formattedAddressFormat.subdivision = state?.name;

    let formattedAddress: string | string[] = addressFormatter.format(
        {
            road: formattedAddressFormat.streetAddress,
            city: formattedAddressFormat.city,
            postcode: formattedAddressFormat.postalCode,
            state: formattedAddressFormat.subdivision,
            country: formattedAddressFormat.country,
            county: formattedAddressFormat.county,
            quarter: formattedAddressFormat.quarter,
            countryCode: company.address.country,
        },
        {
            countryCode: country.code,
            abbreviate: false,
            appendCountry: false,
            cleanupPostcode: false,
            fallbackCountryCode: '',
            output: 'array',
        }
    );

    if (oneLine) {
        // There is a line break at the end of the string, so need to slice the last 2 characters
        if (typeof formattedAddress === 'string') {
            formattedAddress = formattedAddress.replace(/\r?\n/g, ', ').slice(0, -2);
        } else {
            formattedAddress = formattedAddress.map((fa) => fa.trim());
        }
    }

    return formattedAddress;
}
