import _ from 'lodash';
import { GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { getEndorsementLimit, getEndorsementPrice } from './utils/endorsements';
import { ProposedEndorsement, QuotePriceInput } from '@pa/sdk/idf';
import { TransactionType, formatCurrencyAmountByFormatter, getCurrencyFormatter } from '@pa/references/idf';
import { ClientPolicy } from '@pa/sdk/cmf';

import { applyDiscount, displayRateVersion } from './edit-asset/common/utils';
import { policyEndorsementPremiumValueSetter } from './utils/valueSetters';
import { isPinnedBottom } from './utils/ag-grid';
import { parseArgs } from 'util';

export const getEndorsementGridOptions = (
    transactionType: TransactionType,
    currencyFormatter: (params: ValueFormatterParams) => string,
    proposal: any,
    clientPolicy?: ClientPolicy
): GridOptions => {
    const gridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
            width: 100,
        },
        columnDefs: [
            {
                headerClass: 'warning-column',
                cellClass: 'warning-column',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                tooltipValueGetter: () => 'Please enter a premium',
                cellRenderer: (params: any): string => {
                    const price = getEndorsementPrice(
                        params.data,
                        params.data.liabilityLimits?.premises,
                        params.data.liabilityLimits?.public
                    );

                    let invalid = false;

                    if (price > 0) {
                        let priceYear = params.data.clientQuote?.priceYearAdjusted?.find(
                            (py: QuotePriceInput) => py.type === params.data.type
                        );
                        if (!priceYear) {
                            priceYear = params.data.clientQuote?.priceYear?.find(
                                (py: QuotePriceInput) => py.type === params.data.type
                            );
                        }
                        const premium = priceYear?.liability ?? priceYear?.standard;

                        invalid = (premium ?? null) === null;
                    }

                    return invalid ? '<i class="fal fa-bell-on"></i>' : '';
                },
            },
            {
                headerName: 'Name',
                field: 'title',
            },

            // {
            //     headerName: 'Excess',
            //     field: 'excess',
            //     valueFormatter: currencyFormatter,
            // },
            {
                headerName: 'Price',
                cellClass: (params) => `data-testid-${params.data.type}-price`,
                valueGetter: (params: ValueGetterParams) =>
                    getEndorsementPrice(
                        params.data,
                        params.data.liabilityLimits?.premises,
                        params.data.liabilityLimits?.public,
                        params
                    ),
                valueFormatter: currencyFormatter,
            },
            {
                headerName: 'Premium',
                editable: (params) => !isPinnedBottom(params),
                headerClass: 'editable-highlight',
                headerTooltip: 'Click to edit the value',
                valueGetter: (params: ValueGetterParams): number | void => {
                    if (isPinnedBottom(params)) {
                        return params.data.totalPremium;
                    }
                    const priceYear = params.data.clientQuote?.priceYear?.find((pya) => pya.type === params.data.type);

                    const premium = priceYear?.liability ?? priceYear?.standard;

                    if ((premium ?? null) !== null) {
                        return _.round(premium, 2);
                    }
                },
                valueFormatter: currencyFormatter,
                valueSetter: policyEndorsementPremiumValueSetter(transactionType, clientPolicy),
                cellClass: (params) => `data-testid-edit-${params.data.type}-premium`,
            },
            {
                headerName: 'Pro-rata Premium',
                valueGetter: (params: ValueGetterParams): number | void => {
                    if (isPinnedBottom(params)) {
                        return params.data.totalProRata;
                    }

                    let priceYear = params.data.clientQuote?.priceYearAdjusted?.find(
                        (py: QuotePriceInput) => py.type === params.data.type
                    );

                    const premium = priceYear?.liability ?? priceYear?.standard;
                    return applyDiscount(premium, params.data.discountRate);
                },
                valueFormatter: currencyFormatter,
                cellClass: (params) =>
                    isPinnedBottom(params)
                        ? 'data-testid-total-endorsement-premium'
                        : `data-testid-${params.data.type}-premium`,
            },
        ],
        singleClickEdit: true,
        animateRows: true,
        getRowStyle: (params) => (isPinnedBottom(params) ? { 'font-weight': 'bold' } : {}),
    };

    const isVersioned = proposal.policyEndorsements.some((e) => e?.underwritingConfigVersions?.length);
    if (isVersioned) {
        gridOptions.columnDefs.splice(2, 0, {
            headerName: 'Rate Version',
            cellClass: (params) => `data-testid-${params.data.type}-rate-version`,
            valueGetter: (params: ValueGetterParams) => {
                return displayRateVersion(params.data.underwritingConfigVersions);
            },
        });
    }

    const endorsementWithLimits = (proposal.endorsements as ProposedEndorsement[])?.some((e) => !!e.limit);
    if (endorsementWithLimits) {
        gridOptions.columnDefs.splice(2, 0, {
            headerName: 'Limit',
            cellClass: (params) => `data-testid-${params.data.type}-limit`,
            valueGetter: (params: ValueGetterParams) => {
                if (isPinnedBottom(params)) {
                    return '';
                }
                return params.data.proposedEndorsement.limit ?? 0;
            },

            // FIXME: No idea why but this value formatter is getting called for other columns instead of only this one.
            valueFormatter: (params: ValueFormatterParams) => {
                const limitCurrency = params.data.proposedEndorsement?.limitCurrency;
                if (limitCurrency) {
                    if (typeof params.value === 'number' && params.value > 0) {
                        return formatCurrencyAmountByFormatter(params.value, getCurrencyFormatter(limitCurrency));
                    }
                    return '';
                }
                return currencyFormatter(params);
            },
        });
    }

    if ([TransactionType.amendment, TransactionType.cancellation].includes(transactionType)) {
        gridOptions.columnDefs.splice(2, 0, {
            headerName: 'Old Premium',
            cellClass: (params) => `data-testid-${params.data.type}-old-premium`,
            valueFormatter: currencyFormatter,
            valueGetter: (params: ValueGetterParams): number | void => {
                const type = params.data.type;

                const existingPrice = clientPolicy.priceYear.find((py) => py.type === type);
                return existingPrice?.liability ?? existingPrice?.standard;
            },
        });
        if (isVersioned) {
            gridOptions.columnDefs.splice(2, 0, {
                headerName: 'Old Rate Version',
                cellClass: (params) => `data-testid-${params.data.type}-old-rate-version`,
                valueGetter: (params: ValueGetterParams) => {
                    const type = params.data.type;
                    const version =
                        clientPolicy.clientEndorsements.find((e) => e.type === type)?.underwritingConfigVersions ?? [];
                    return displayRateVersion(version);
                },
            });
        }
    }

    return gridOptions;
};
