<ng-template #confirmDialog>
    <mat-dialog-content [style.minWidth.px]="300">
        <div>
            <h1 data-testid="confirm-dialog-title">{{ title }}</h1>
            <h2 data-testid="confirm-dialog-content" *ngIf="status" style="font-size: 15px;" class="text-center">{{
                content }}</h2>
            <div data-testid="confirm-dialog-content" *ngIf="!status">{{ content }}</div>
            <div *ngIf="status">
                <mat-label data-testid="confirm-dialog-label">When would you like to this cancellation into
                    effect?</mat-label>
                <br>
                <mat-form-field style="border-bottom: 1px solid; width:100%;">
                    <input style="border: none;" matInput [matDatepicker]="picker" [(ngModel)]="formatDate"
                        [min]="currentDate" [max]="expiryDate" data-testid="cancellation-effective-date">
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                </mat-form-field>
            </div>
            <div *ngIf="showInceptionButton" class="text-center mt-1">
                <button mat-button class="border border-primary text-primary" type="button"
                    (click)="cancelFromInception()" data-testid="confirm-dialog-inception-date-button">
                    Cancel from Inception
                </button>
            </div>
            <ng-content></ng-content>
            <div class="text-center mt-4 mb-1">
                <button mat-raised-button type="button" (click)="close()" data-testid="confirm-dialog-close-button">
                    Close
                </button>
                <button *ngIf="confirmBtnLabel" mat-raised-button class="ml-2 btn btn-primary" type="button"
                    cdkFocusInitial (click)="confirm()" data-testid="confirm-dialog-confirm-button">
                    {{ confirmBtnLabel }}
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>