<div *ngIf="showPage">
    <div class="row justify-content-center">
        <div class="row justify-content-center" *ngIf="behavioursLoading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="col-4" *ngIf="!behavioursLoading">
            <behaviour-selection
                [behaviours]="behaviours"
                (behaviourUpdated)="updateCurrentBehaviour($event)"
            ></behaviour-selection>
            <div class="row justify-content-center">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="col-md-3 col-11 btn btn-primary"
                    [disabled]="!currentBehaviour"
                    data-testid="next"
                    (click)="selectBehaviour()"
                >
                    Submit
                </button>
            </div>
        </div>
    </div>

    <div id="onboard" class="row justify-content-center">
        <button
            mat-raised-button
            class="col-md-2 col-sm-6"
            data-testid="btn-add-organisation"
            (click)="openOrganisationModal()"
        >
            Add Organisation
        </button>
        <button
            mat-raised-button
            class="col-md-2 col-sm-6"
            data-testid="btn-add-originator"
            (click)="openOriginatorModal()"
        >
            Add Broker
        </button>
    </div>

    <div class="row justify-content-center mt-3" *ngIf="selectedBehaviour || organisationSearchQuery">
        <form class="col-4" [formGroup]="form" (submit)="searchOrganisation()">
            <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
            <div class="row justify-content-center">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="mb-3 btn btn-primary"
                    [disabled]="form.invalid"
                    data-testid="next"
                >
                    Search Organisation
                </button>
            </div>
        </form>
    </div>

    <div id="organisation" *ngIf="selectedBehaviour || organisationSearchQuery" class="col-md-6 col-sm-12">
        <ag-grid-angular
            class="col-12"
            domLayout="autoHeight"
            class="ag-theme-material"
            [rowData]="organisations"
            [gridOptions]="organisationGridOptions"
            (columnResized)="resizeGrids()"
        ></ag-grid-angular>
    </div>

    <add-organisation #organisationDialog [behaviours]="[selectedBehaviour]"></add-organisation>
    <add-originator #originatorDialog [behaviours]="[selectedBehaviour]"></add-originator>
</div>
