import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { UserInterfaceType } from '@pa/references';
import { FeatureFlags } from '@pa/references/idf';
import { UiConfigRefType } from '@pa/sdk/idf';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { SdkService } from '../../services/sdk.service';

export enum ApplicationState {
    /** Authenticated and post-sign in actions completed */
    ready = 'ready',

    /** Everything else */
    notReady = 'notReady',
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    public userSignedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _appState = new BehaviorSubject<ApplicationState>(ApplicationState.notReady);
    private _authState = new BehaviorSubject<AuthenticatorService['route'] | undefined>(undefined);
    private _authData = new BehaviorSubject<AuthenticatorService['user'] | undefined>(undefined);
    private _featureFlags = new BehaviorSubject<UiConfigRefType<FeatureFlags>[] | undefined>(undefined);
    private _userGroups = new BehaviorSubject<string[]>([]);

    public authState = this._authState.asObservable();

    public featureFlags = this._featureFlags.asObservable();

    constructor(public authenticator: AuthenticatorService, private sdkService: SdkService) {}

    get appState() {
        return this._appState.asObservable();
    }

    get userGroups() {
        return this._userGroups.asObservable();
    }

    private _setAuthState(state: AuthenticatorService['route']) {
        this._authState.next(state);
    }

    private _setAuthData(data: AuthenticatorService['user']) {
        this._authData.next(data);
    }

    private _setFeatureFlags(data: UiConfigRefType<FeatureFlags>[]) {
        this._featureFlags.next(data);
    }

    async isLoggedIn() {
        try {
            await Auth.currentSession();
            return true;
        } catch (err) {
            // Suppress error
            return false;
        }
    }

    async postSignIn() {
        try {
            const currentSession = await Auth.currentSession();
            this.sdkService.idf.accessToken = currentSession.getAccessToken().getJwtToken();
            const uiConfigs = await this.sdkService.idf.UiConfigs.get(UserInterfaceType.insurerPortfolioManagement);
            this._setFeatureFlags(Array.isArray(uiConfigs) ? uiConfigs[0].featureFlags : uiConfigs.featureFlags);

            this.userSignedInSubject.next(true);
            this._setAuthState(this.authenticator.route);
            this._userGroups.next(currentSession.getIdToken().payload['cognito:groups'] ?? []);
            this._appState.next(ApplicationState.ready);
        } catch (err) {
            this._setFeatureFlags([]);
        }
    }
}
