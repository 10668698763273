<!-- Title -->
<div id="title-bar">
    <span class="col-12">
        <h1>Search Quotes &amp; Policies</h1>
    </span>
</div>

<div>
    <!-- Behaviour Selector -->
    <div class="mb-4">
        <div *ngIf="showBehaviourSelector" class="row justify-content-center">
            <div class="row justify-content-center" *ngIf="behavioursLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="col-4" *ngIf="!behavioursLoading">
                <behaviour-selection
                    [behaviours]="behaviours"
                    (behaviourUpdated)="updateBehaviour($event)"
                ></behaviour-selection>
                <div class="row justify-content-center">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="col-md-3 col-11 btn btn-primary"
                        [disabled]="!behaviourSelected"
                        (click)="searchByBehaviour()"
                        data-testid="next"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!showBehaviourSelector">
            <button
                mat-raised-button
                data-testid="showBehaviourSelector"
                (click)="showBehaviourSelector = !showBehaviourSelector"
            >
                Show Behaviour Selector
            </button>
        </div>
    </div>

    <!-- Search Input Field -->
    <div class="d-flex justify-content-center my-3">
        <mat-form-field style="width: 42.5%" floatLabel="never">
            <input
                matInput
                type="text"
                placeholder="Search by Insured name, Insurance Contact, Policy Reference or Asset Serial Number"
                data-testid="searchInput"
                [value]="searchQuery"
                [(ngModel)]="searchQuery"
                (input)="search()"
                [disabled]="searchDisabled"
            />
        </mat-form-field>
    </div>

    <!-- Table -->
    <mat-card *ngIf="showTable" data-testid="showTable">
        <div class="row justify-content-center" *ngIf="dataLoading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="!dataLoading">
            <ag-grid-angular
                class="w-100 ag-theme-material"
                domLayout="autoHeight"
                [gridOptions]="gridOptions"
                [pagination]="true"
                [paginationPageSize]="paginationPageSize"
                [rowData]="rowData"
                (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </div>
    </mat-card>
</div>
