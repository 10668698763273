<ng-template #editAsset>
    <h1 mat-dialog-title>{{ title }}</h1>
    <mat-dialog-content [style.minWidth.px]="1050">
        <!-- <div *ngIf="loading" class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div> -->
        <div [ngSwitch]="model.assetType">
            <aircraft-info *ngSwitchCase="'aircraft'" [asset]="model" (validated)="onValidated($event)"></aircraft-info>
            <uav-info *ngSwitchCase="'uav'" [asset]="model" (validated)="onValidated($event)"></uav-info>
            <payload-info *ngSwitchCase="'payload'" [asset]="model" (validated)="onValidated($event)"></payload-info>
            <equipment-info
                *ngSwitchCase="'equipment'"
                [asset]="model"
                (validated)="onValidated($event)"
            ></equipment-info>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: center">
        <button
            mat-raised-button
            color="primary"
            [disabled]="!isValid"
            type="button"
            class="btn btn-primary"
            (click)="update()"
            attr.data-testid="editAssetModal-submit-button"
        >
            Update
        </button>
    </mat-dialog-actions>
</ng-template>
