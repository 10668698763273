import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import moment from 'moment-timezone';
import { PolicyState } from '@pa/references/cmf';
import { TransactionType, FeatureFlags, QuoteStatus, OriginatorType } from '@pa/references/idf';
import { forkJoin, of, Observable, from } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { QuoteService } from './quote.service';
import { PolicyService } from './policy.service';
import { ClientProposal, ClientQuote, Originator } from '@pa/sdk/idf';
import { ClientPolicy } from '@pa/sdk/cmf';
import { SdkService } from './sdk.service';

interface DisplayLinks {
    showEditProposal?: boolean;
    hideResend?: boolean;
    hideRegenerate?: boolean;
    hideReassignBroker?: boolean;
}

export enum CustomerActionsComponents {
    'viewQuote',
    'underwriting',
}

@Injectable({
    providedIn: 'root',
})
export class CustomerActionsService {
    constructor(
        private authService: AuthService,
        private quoteService: QuoteService,
        private policyService: PolicyService,
        private _sdk: SdkService
    ) {}

    defaultValueOfLinks: DisplayLinks = {
        hideReassignBroker: true,
        hideRegenerate: true,
        hideResend: true,
        showEditProposal: false,
    };

    getProposalsArray(
        byReference: ClientProposal | ClientProposal[],
        byPolicy: ClientProposal | ClientProposal[]
    ): ClientProposal[] {
        if (byReference) {
            return Array.isArray(byReference) ? byReference : [byReference];
        } else if (byPolicy) {
            return Array.isArray(byPolicy) ? byPolicy : [byPolicy];
        }
        return [];
    }

    getCustomerServiceActionLinks(
        componentType: CustomerActionsComponents,
        proposalId: string,
        behaviourId: string,
        clientProposal: ClientProposal,
        clientQuote: ClientQuote,
        clientPolicyNumber: string | undefined,
        originator?: Originator
    ): Observable<DisplayLinks> {
        const NOW = moment().startOf('day').toISOString();
        const invalidQuoteStatus = [
            QuoteStatus.dirty,
            QuoteStatus.filthy,
            QuoteStatus.declined,
            QuoteStatus.coverageHeldDirty,
            QuoteStatus.coverageHeldFilthy,
        ];
        const quoteStatusValid = !invalidQuoteStatus.includes(clientQuote.status);
        const isNbRnTransaction = [TransactionType.newBusiness, TransactionType.renewal].includes(
            clientProposal.transactionType
        );
        const isNotCNTransaction = clientProposal.transactionType !== TransactionType.cancellation;
        const isAMDTransaction = clientProposal.transactionType === TransactionType.amendment;
        const isProvisioned = clientQuote.status === QuoteStatus.provisioned;
        const isUnacceptedExpiredQuote = !isProvisioned && clientQuote.expiryDate < NOW;
        const isNotExpiredQuote = clientQuote.expiryDate > NOW;
        const isNotExpiredHCQuote = clientQuote.holdCoverProposal
            ? clientQuote.holdCoverProposal.expiryDate > NOW
            : true;

        return this.authService.featureFlags.pipe(
            switchMap((flags) => {
                const enabledFeatureFlags = flags.map((f) => f.type);
                const userGroups = this.authService.userGroups.pipe(take(1));
                let latestProposal: Observable<ClientProposal> = of(undefined),
                    proposalsByReference: Observable<ClientProposal | ClientProposal[]> = of(undefined),
                    proposalsByClientPolicy: Observable<ClientProposal | ClientProposal[]> = of(undefined),
                    latestPolicyByReference: Observable<ClientPolicy[]> = of(undefined);

                if (
                    enabledFeatureFlags.includes(FeatureFlags.insurerRegenerateDocuments) ||
                    enabledFeatureFlags.includes(FeatureFlags.reAssignBroker)
                ) {
                    latestProposal = this.quoteService.getLatestProposal(proposalId, behaviourId);
                }

                if (enabledFeatureFlags.includes(FeatureFlags.underwriterEditProposal)) {
                    if (isNbRnTransaction) {
                        proposalsByReference = from(
                            this.quoteService.getProposalsByReferenceIncludingQuote(
                                clientProposal.reference,
                                behaviourId
                            )
                        );
                    } else if (isAMDTransaction && clientPolicyNumber) {
                        proposalsByClientPolicy = from(
                            this.quoteService.getProposalsByClientPolicyIncludingQuote(
                                clientProposal.clientPolicy,
                                behaviourId
                            )
                        );
                        latestPolicyByReference = from(
                            this.policyService.getLatestPolicyByReference(clientPolicyNumber, true, behaviourId)
                        );
                    }
                }
                return forkJoin([
                    userGroups,
                    latestProposal,
                    proposalsByReference,
                    proposalsByClientPolicy,
                    latestPolicyByReference,
                    of(enabledFeatureFlags),
                ]);
            }),
            map((res) => {
                const [
                    userGroups,
                    latestProposal,
                    proposalsByReference,
                    proposalsByClientPolicy,
                    clientPolicy,
                    enabledFeatureFlags,
                ] = res;

                let displayLinkValues: DisplayLinks = this.defaultValueOfLinks;
                const clientProposals = this.getProposalsArray(proposalsByReference, proposalsByClientPolicy);
                const isNotProvisioned = clientProposals.every(
                    (pr) => pr.clientQuote?.status !== QuoteStatus.provisioned
                );
                const isICMCSGroup = userGroups.includes('ipm_cs.write');
                const isClientPolicyNotRenewed =
                    clientPolicy && clientPolicy.length ? clientPolicy[0].policyState !== PolicyState.renewed : true;

                switch (componentType) {
                    case CustomerActionsComponents.underwriting:
                        displayLinkValues = {
                            showEditProposal:
                                enabledFeatureFlags.includes(FeatureFlags.underwriterEditProposal) &&
                                isNotCNTransaction &&
                                isNotExpiredHCQuote &&
                                isNotExpiredQuote &&
                                isNotProvisioned &&
                                isICMCSGroup &&
                                isClientPolicyNotRenewed,
                        };
                        break;
                    case CustomerActionsComponents.viewQuote:
                        if (latestProposal?._id.toString() === clientProposal._id.toString()) {
                            displayLinkValues = {
                                hideReassignBroker:
                                    !enabledFeatureFlags.includes(FeatureFlags.reAssignBroker) ||
                                    originator.type !== OriginatorType.intermediary ||
                                    !quoteStatusValid ||
                                    isProvisioned ||
                                    !isNbRnTransaction ||
                                    isUnacceptedExpiredQuote,
                                hideRegenerate:
                                    !enabledFeatureFlags.includes(FeatureFlags.insurerRegenerateDocuments) ||
                                    !quoteStatusValid ||
                                    isProvisioned ||
                                    isUnacceptedExpiredQuote,
                                hideResend:
                                    !enabledFeatureFlags.includes(FeatureFlags.insurerResendNotification) ||
                                    !quoteStatusValid ||
                                    isProvisioned ||
                                    isUnacceptedExpiredQuote,
                            };
                        }
                        displayLinkValues = {
                            showEditProposal:
                                enabledFeatureFlags.includes(FeatureFlags.underwriterEditProposal) &&
                                isNotCNTransaction &&
                                isNotExpiredHCQuote &&
                                isNotExpiredQuote &&
                                isNotProvisioned &&
                                isICMCSGroup &&
                                isClientPolicyNotRenewed,
                        };
                        break;
                    default:
                        break;
                }

                return displayLinkValues;
            })
        );
    }
}
