<mat-card class="mb-3" *ngIf="showExistingPolicy">
    <mat-card-header>
        <mat-card-title>Existing Endorsements</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ag-grid-angular
            domLayout="autoHeight"
            class="ag-theme-material"
            [gridOptions]="existingEndorsementsGridOptions"
            [rowData]="existingEndorsements"
            (cellValueChanged)="rowDataChanged($event, endorsementsGridOptions)"
            (gridSizeChanged)="resize($event, endorsementsGridOptions)"
            [getRowStyle]="getRowStyle"
            style="width: 100%"
        ></ag-grid-angular>
    </mat-card-content>
</mat-card>
<mat-card class="mb-3">
    <mat-card-header>
        <mat-card-title>Proposed Endorsements</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ag-grid-angular
            domLayout="autoHeight"
            class="ag-theme-material"
            [gridOptions]="endorsementsGridOptions"
            [rowData]="asset.endorsements"
            (cellValueChanged)="rowDataChanged($event, endorsementsGridOptions)"
            (gridSizeChanged)="resize($event, endorsementsGridOptions)"
            [getRowStyle]="getRowStyle"
        ></ag-grid-angular>
    </mat-card-content>
</mat-card>
