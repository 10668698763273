import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EditCellComponent } from './edit-cell/edit-cell.component';

@NgModule({
    imports: [CommonModule, MatButtonModule],
    declarations: [EditCellComponent],
})
export class FormComponentsModule {}
