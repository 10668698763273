import { Component, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';
import { Theme } from '@pa/sdk/idf';
import moment, { Moment } from 'moment-timezone';
import { ClientPolicy } from '@pa/sdk/cmf';
import { PolicyState } from '@pa/references/cmf';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
    @ViewChild('confirmDialog', { static: false }) confirmDialog: TemplateRef<any> | undefined;

    @Input() title: string = 'Confirm';
    @Input() confirmBtnLabel: string | undefined = 'Confirm';

    @Output() confirmClicked = new EventEmitter();
    @Output() closeClicked = new EventEmitter();
    @Output() cancellationDate = new EventEmitter<string>();

    dialogRef: MatDialogRef<any> | undefined;

    theme: Theme;
    content: string = '';
    status: boolean = false;
    expiryDate: Moment;
    inceptionDate: string;
    formatDate: Moment = moment();
    currentDate: Moment = moment();
    timezone: string = 'UTC';

    showInceptionButton: boolean;

    constructor(public dialog: MatDialog, private themeService: ThemeService, private ngZone: NgZone) {
        this.theme = themeService.default;
        themeService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    ngOnInit() {
        this.status = false;
        this.showInceptionButton = false;
    }

    open(content: string, title?: string) {
        this.status = false;
        this.showInceptionButton = false;
        this.content = content;
        this.title = title === undefined ? this.title : title;
        if (this.confirmDialog) {
            this.ngZone.run(() => {
                this.dialogRef = this.dialog.open(this.confirmDialog, {
                    data: {
                        content,
                        title: this.title,
                        confirmBtnLabel: this.confirmBtnLabel,
                    },
                    panelClass: THEME_NAME,
                });
            });
        }
    }

    openCancelDialog(content: string, clientPolicy: ClientPolicy) {
        this.status = true;
        this.content = content;

        this.timezone = clientPolicy.timezone;
        this.expiryDate = moment(clientPolicy.expiryDate).tz(this.timezone).subtract({ day: 1 });

        const expiringAmendedStates = [PolicyState.amended, PolicyState.expiringAmended, PolicyState.expiring];
        let inceptionDate = clientPolicy.inceptionDate;
        if (clientPolicy.policyState === PolicyState.renewed) {
            inceptionDate = clientPolicy.renewalDate;
        } else if (expiringAmendedStates.includes(clientPolicy.policyState)) {
            inceptionDate = clientPolicy.renewalDate ?? clientPolicy.inceptionDate;
        }
        inceptionDate = clientPolicy.amendedDate > inceptionDate ? clientPolicy.amendedDate : inceptionDate;

        this.inceptionDate = inceptionDate;
        const now = moment().tz(this.timezone).toISOString();
        let minDateString = inceptionDate > now ? inceptionDate : now;

        this.currentDate = moment(minDateString).tz(this.timezone);
        this.formatDate = this.currentDate.clone();

        this.showInceptionButton = true;
        if (this.confirmDialog) {
            this.ngZone.run(() => {
                this.dialogRef = this.dialog.open(this.confirmDialog, {
                    data: {
                        content,
                        title: this.title,
                        expiryDate: this.expiryDate,
                        inceptionDate: this.inceptionDate,
                        confirmBtnLabel: this.confirmBtnLabel,
                    },
                    panelClass: THEME_NAME,
                });
            });
        }
    }

    cancelFromInception() {
        this.formatDate = moment(this.inceptionDate).tz(this.timezone);
    }

    close() {
        this.closeClicked.emit();
        this.ngZone.run(() => this.dialogRef?.close());
    }

    confirm() {
        this.confirmClicked.emit();
        if (this.status === true) {
            this.cancellationDate.emit(moment(this.formatDate).tz(this.timezone).toISOString());
        }

        this.close();
    }
}
