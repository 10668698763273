import { Component, OnInit } from '@angular/core';
import { FeatureFlags } from '@pa/references/idf';
import { DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'cp-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
    constructor(private authService: AuthService) {}

    options: GridsterConfig = {};
    dashboard: Array<GridsterItem> = [];
    commonConfig = { cols: 1, rows: 1, y: 0, x: 0, layerIndex: -1 };
    loading: boolean = false;

    ngOnInit() {
        this.loading = true;
        this.options = {
            draggable: { enabled: false },
            minCols: 3,
            maxCols: 5,
            minRows: 2,
            maxRows: 5,
            margin: 30,
            disableWindowResize: false,
            displayGrid: DisplayGrid.None,
            gridType: GridType.Fixed,
            fixedColWidth: 350, // only works with GridType.Fixed default:250
            fixedRowHeight: 200,
        };

        this.authService.featureFlags.subscribe({
            next: (flags) => {
                flags?.forEach((flag) => {
                    switch (flag.type) {
                        case FeatureFlags.insurerCustomerServicing: {
                            this._addDashboardItem('', 'Customer Servicing', 'quotes-policies');
                            break;
                        }
                        case FeatureFlags.insurerDistributionManagement: {
                            this._addDashboardItem('', 'Broker/Affinity Management', 'distribution-management');
                            break;
                        }
                        case FeatureFlags.insurerRateManagementView: {
                            this._addDashboardItem('', 'Rate Management', 'rate-management');
                            break;
                        }
                        case FeatureFlags.insurerReferralManagement: {
                            this._addDashboardItem('', 'Referrals', 'referrals');
                            break;
                        }
                        case FeatureFlags.insurerReporting: {
                            this._addDashboardItem('', 'Reporting', '');
                            break;
                        }
                        case FeatureFlags.insurerRateVersioningView: {
                            this._addDashboardItem('', 'Stored Underwriting Configurations', 'view-underwriting');
                            break;
                        }
                        default:
                            break;
                    }
                });
                this.loading = false;
            },
        });
    }

    changedOptions() {
        this.options.api.optionsChanged();
    }

    private _addDashboardItem(content: string, heading: string, link: string): void {
        const index = this.dashboard.findIndex((board) => board.heading === heading);
        index === -1
            ? this.dashboard.push({
                  ...this.commonConfig,
                  content: content,
                  heading: heading,
                  link: link,
              })
            : null;
        return;
    }
}
