import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';
import { Auth, Hub } from 'aws-amplify';
import version from '../../package.json';
import { ApplicationState, AuthService } from './core/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    appReady: boolean = false;
    authState: boolean = false;
    public theme: string;
    public themeName = THEME_NAME;
    public version = version;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private themeService: ThemeService
    ) {}

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {});
        this.themeService.theme.subscribe((theme) => {
            this.theme = theme.slug;
        });

        this.authService.appState.subscribe((state) => {
            this.appReady = state === ApplicationState.ready;
        });

        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    if (localStorage.getItem('redirectUrl')) {
                        const redirectUrl = JSON.parse(localStorage.getItem('redirectUrl'));
                        localStorage.removeItem('redirectUrl');
                        this.router.navigateByUrl(redirectUrl);
                    }
                    await this.authService.postSignIn();
                    break;
                case 'signUp':
                    // console.log('user signed up');
                    break;
                case 'signOut':
                    // console.log('user signed out');
                    break;
                case 'signIn_failure':
                    // console.log('user sign in failed');
                    break;
                case 'configured':
                // console.log('the Auth module is configured');
            }
        });

        const isLoggedIn = await this.authService.isLoggedIn();

        if (isLoggedIn) {
            // When the user is already logged in
            await this.authService.postSignIn();
        }

        if (!isLoggedIn) {
            // save the current URL to redirect after successful authentication
            const currentPath = window.location.href.replace(window.location.origin, '');
            localStorage.setItem('redirectUrl', JSON.stringify(currentPath));

            // User is not logged in, redirect to Provider's Auth page
            await Auth.federatedSignIn().catch((error) => {
                console.error({ error });
                return Auth.signOut();
            });
        }
    }
}
