import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmendType } from '@pa/references/idf';
import { AssetModel } from '../types';

@Component({
    selector: 'uav-info',
    templateUrl: './uav-info.component.html',
})
export class UavInfoComponent implements OnInit {
    @Input() asset: AssetModel;
    @Output() validated = new EventEmitter<boolean>();

    premiumsValid: boolean = false;
    endorsementsValid: boolean = false;

    ngOnInit() {}

    onPremiumsValidated(isValid: boolean) {
        this.premiumsValid = isValid;
        this.validate();
    }

    onEndorsementsValidated(isValid: boolean) {
        this.endorsementsValid = isValid;
        this.validate();
    }

    validate(): void {
        this.validated.emit(
            this.premiumsValid && (this.asset.amendType === AmendType.removed || this.endorsementsValid)
        );
    }
}
