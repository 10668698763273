export interface NavItem {
    displayName: string;
    disabled?: boolean;
    iconName: string;
    route?: string;
    children?: NavItem[];

    /** Test id for e2e testing */
    testid?: string;
}

export interface CognitoToken {
    accessToken: string;
    idToken: string;
}

export const cognitoToken: CognitoToken = {
    accessToken: '',
    idToken: '',
};

export const AUTH_STATE = 'signedin';

export const DECLINE_REASONS = [
    {
        value: 'Conflict of Interest',
        label: 'Conflict of Interest',
    },
    {
        value: 'Outside of Underwriting Guidelines',
        label: 'Outside of Underwriting Guidelines',
    },
    {
        value: 'Too Many Claims',
        label: 'Too Many Claims',
    },
    {
        value: 'Too long until Renewal/proposed inception',
        label: 'Too long until Renewal/proposed inception',
    },
];

export const DATE_FORMAT = 'DD MMM YYYY';
